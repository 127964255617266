import format from "date-fns/format";

export function saveLanguageToCookie(choosenLanguage) {
  //calculate a new date with UTC
  let date = new Date();
  let now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  //remove cookie if exsist and then set the new one
  if (getCookie("sitelanguage") != "") {
    document.cookie = `sitelanguage=''; expires=${
      now_utc + date.getUTCDate()
    } UTC; path=/`;
  }
  document.cookie = `sitelanguage=${choosenLanguage}; expires=${
    now_utc + date.getUTCDate() + 14
  } UTC; path=/`;
}

export function getCookie(name) {
  const parts = `; ${document.cookie}`.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function progressStatus(percentage) {
  let variant = "";

  if (percentage > 0) variant = "danger";
  if (percentage >= 50) variant = "warning";
  if (percentage >= 80) variant = "success";
  return variant;
}

export function dateFormater(date) {
  return format(date, "yyyy-MM-dd");
}

export function AppendOnHeader(element, elemLink) {
  const script = document.createElement(element);
  if (element === "script") {
    script.src = elemLink;
    script.async = true;
  } else {
    script.type = "text/css";
    script.rel = "stylesheet";
    script.href = elemLink;
  }
  document.body.appendChild(script);

  return script;
}

export const errorHandler = (status) => {
  let message = "";
  switch (status) {
    case 302:
      message = "something-worng-err";
      break;

    case 400:
    case 401:
      message = "authorization-err";
      break;

    case 404:
      message = "not-found";
      break;

    default:
      message = "server-err";
      break;
  }

  location.assign(`/something-wrong/?catchedMessage=${message}`);
};
