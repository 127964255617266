import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import Renewal from "../../../pages/Renewal";
import CountDownTimer from "../CountDownTimer";
import SelfSurvey from "../../SelfSurvey";
import { claimingByMail } from "../../../services/certificateService";

import "./styles.scss";

const CertificateModal = (props) => {
  const {
    eligible,
    requested,
    isExpired,
    eligibilityData,
    currentUserLevel,
    setCountDownEnded,
    descriptionMessage,
    hCTRecordCohortStudent,
    talaaqaStudent,
    coolDownTime,
    classData,
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openSelfSurvey, setOpenSelfSurvey] = useState(false);
  const [claim, setClaim] = useState(false);
  const [issuingCertificate, setIssuingCertificate] = useState(false);
  const isEb =
    localStorage.getItem("isEb") != "undefined" &&
    JSON.parse(localStorage.getItem("isEb"));
  const token = localStorage.getItem("token");
  const [linkedin, setLinkedin] = useState("");
  const [userLevel, setUserLevel] = useState();
  const [description, setDescription] = useState();
  const [sameLevel, setSameLevel] = useState(true);
  const [timeStatus, setTimeStatus] = useState(true);
  const [shareStatus, setShareStatus] = useState(false);
  const [levelCompletion, setLevelCompletion] = useState();
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const enterpriseObj = JSON.parse(localStorage.getItem("enterpriseObj"));
  const [userName, setUserName] = useState(userData["name"]);

  async function getUserCurrentLevel() {
    setUserLevel(currentUserLevel?.name);
    setLevelCompletion(currentUserLevel?.complete);
  }

  const handleClickOpen = () => {
    isEb?.post_survey_status == false ? setOpenSelfSurvey(true) : setOpen(true);
  };

  const handleClose = () => {
    if (shareStatus == true) {
      setOpen(false);
      location.reload();
    } else {
      setOpen(false);
    }
  };

  const handleNameChange = (e) => {
    if (e.target.value != "") setUserName(e.target.value);
    else setUserName(userData["name"]);
  };

  const handleStayOnSameLevel = () => {
    window["dataLayer"].push({
      event: "stay_in_level",
      level_completion: levelCompletion,
      level_name: userLevel,
    });
    handleClose();
  };

  const eligibleBtnAction = async () => {
    if (eligible && !claim) {
      //navigate to new exercises module with {exit testId
      if (
        eligibilityData?.is_passed === false &&
        isEb.hasOwnProperty("post_survey_status") === false &&
        hCTRecordCohortStudent === false
      ) {
        location.assign(
          `${process.env.REACT_APP_REDIRECTION_LINK}?token=${token.replaceAll(
            '"',
            ""
          )}&type=exit&scheduleType=${
            classData?.scheduled_class_type_id
          }&classTypeId=${classData?.class_type_id}&isEb=${
            isEb ? "true" : "false"
          }&enterprise=${enterpriseObj?.enterprise_name}`
        );
      } else {
        setSameLevel(false);
        setClaim(true);
        setDescription(t("certificate-popup.certificate-issuer-name"));
      }
    } else if (claim && eligible) {
      setIssuingCertificate(true);
      const certificateResult = await claimingByMail(userName, classData);
      if (certificateResult?.data.success == true) {
        window["dataLayer"].push({
          event: "redeem_certificate",
          level_completion: levelCompletion,
          level_name: userLevel,
        });
        setDescription(t("certificate-popup.congratulations"));
        setShareStatus(true);
        setLinkedin(certificateResult?.data?.data?.linkedin_certificate_url);
        setIssuingCertificate(false);
        setClaim(false);
      } else {
        setOpen(true);
      }
    } else if (!eligible) handleClose();
  };

  const surveyStatus = (status) => {
    if (status == true) {
      setOpenSelfSurvey(false);
      setOpen(true);
    }
  };

  useEffect(() => {
    getUserCurrentLevel();
    if (isEb && isEb.hasOwnProperty("post_survey_status") == true) {
      setSameLevel(false);
      setClaim(true);
      setDescription(t("certificate-popup.certificate-issuer-name"));
    }
  }, []);

  return (
    <div className="certificate" onClick={($e) => $e.stopPropagation()}>
      <div
        onClick={handleClickOpen}
        disabled={props.disabled}
        className={`btn englease--f-12 certificate__btn ${
          eligible === false ? "englease--unClick" : "englease--click"
        }`}
        style={{ color: talaaqaStudent && "#fff" }}
      >
        {props.buttonName}
      </div>
      {openSelfSurvey ? (
        <SelfSurvey surveyStatus={surveyStatus} />
      ) : (
        <Dialog maxWidth="lg" open={open} onClose={handleClose}>
          {isExpired === true ? (
            <Renewal enterpriseName={enterpriseObj?.enterprise_name} />
          ) : (
            <>
              <DialogTitle className="mb-2">
                <section className="text-center">
                  <DialogActions className="p-0">
                    <button onClick={handleClose} className="p-0 btn">
                      <span className="englease__txt--muteTxt opacity-50 englease--f-24 mb-0 p-0">
                        X
                      </span>
                    </button>
                  </DialogActions>
                  {/* <i className={`${props.classPassed}`}></i> */}
                  <div>
                    <span className="englease--f-24 englease--bold">
                      {props.title}
                    </span>
                  </div>
                  <div className="englease__txt--gray opacity-75 englease--f-14 my-3 certificate__description-list">
                    {description ? description : descriptionMessage}
                  </div>
                  {claim && !requested && !shareStatus && (
                    <TextField
                      fullWidth
                      name="Name"
                      label={t("certificate-popup.new-name")}
                      placeholder={userData["name"]}
                      onChange={handleNameChange}
                      inputProps={{
                        className: "englease__txt--black englease",
                      }}
                      InputLabelProps={{
                        className:
                          "englease englease__txt--black englease--f-14 reset-password--input-position reset-password__label ",
                      }}
                    />
                  )}
                </section>
              </DialogTitle>
              {!requested && (
                <DialogContent className="p-0">
                  <DialogContentText className="d-flex flex-column gap-3">
                    {eligible && sameLevel && isEb === false && (
                      <button
                        onClick={handleStayOnSameLevel}
                        className="py-3 englease--f-16 bg-transparent text-center  englease__radius--f-8 englease__border--main englease__txt--main"
                      >
                        {t("certificate-popup.keep-same-level")}
                      </button>
                    )}
                    {shareStatus == true ? (
                      <div className="d-flex align-items-center px-2 englease__bg--secondary  englease__radius--f-8 englease englease--f-14 englease__txt--white ">
                        <div className="d-flex align-items-center">
                          <i className="iET-linkedin englease__bg--contain iET-s24"></i>
                        </div>
                        <a
                          href={linkedin}
                          target="_blank"
                          className="d-block w-100 border-0 py-3  text-center englease englease__txt--white englease--f-14"
                        >
                          {t("certificate-popup.linkedin")}
                        </a>
                      </div>
                    ) : eligible &&
                      hCTRecordCohortStudent === false &&
                      eligibilityData?.is_passed === false &&
                      coolDownTime > Number("0") ? (
                      <div className="row g-0 justify-content-center border-0 py-3 englease--f-16 englease__radius--f-8 text-center">
                        <h3>{t("certificate-popup.cool-down-msg")}</h3>
                        <CountDownTimer
                          timer={coolDownTime}
                          getTimeStatus={setTimeStatus}
                          setCountDownEnded={setCountDownEnded}
                        />
                      </div>
                    ) : (
                      <button
                        onClick={eligibleBtnAction}
                        className={`${
                          eligible === false ||
                          requested === true ||
                          issuingCertificate
                            ? "englease--unClick"
                            : "englease--click"
                        } d-block w-100 border-0 py-3 englease--f-16 englease__bg--main englease__radius--f-8 englease__txt--white text-center`}
                        disabled={
                          eligible === false ||
                          requested === true ||
                          issuingCertificate
                        }
                      >
                        {claim
                          ? t("certificate-popup.claim-now")
                          : hCTRecordCohortStudent === true ||
                            (eligible &&
                              eligibilityData?.is_passed === true &&
                              requested === false) ||
                            isEb
                          ? t("certificate-popup.claim-and-level-up")
                          : eligible && requested === false
                          ? t("certificate-popup.claim-and-start-test")
                          : t("certificate-popup.continue-my-classes")}
                      </button>
                    )}
                  </DialogContentText>
                </DialogContent>
              )}
            </>
          )}
        </Dialog>
      )}
    </div>
  );
};

export default CertificateModal;
